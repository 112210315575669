import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const MehaaAmirthalingam = () => (
  <Layout
    title="Alumni Spotlight - Mehaa Amirthalingam"
    className="alumni-center bios"
  >
    {/* Alumni Profile */}
    <Section className="alumni">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Mehaa Amirthalingam</li>
        </ul>
        <Row>
          <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Mehaa Amirthalingam</h1>
            <h3 className="alumni__profile-type">
              2018 Young Scientist Challenge Finalist
            </h3>
            <h4 className="alumni__profile-project">
              Mehaa developed a toilet flushing system that uses both fresh and
              recycled water to reduce water consumption in the home.
            </h4>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Mehaa-Amirthalingam-2018-Finalist-Quote.jpg" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “Innovation should not degrade humanity for the sake of
                  personal growth.”
                </h4>
                <h3>Mehaa Amirthalingam</h3>
                <p>2018 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="alumni__bio box-shadow hover imagezoom">
              <Image filename="Faraz-Tamboli-Alumni-Card.jpg" />
              <div className="p-1.5">
                <span>2019 Finalist</span>
                <h3>Faraz Tamboli</h3>
                <p>
                  Since participating in the 2019 Challenge, Faraz has continued
                  to improve and enhance his entry project, Talk Motion. Faraz
                  is currently in 9th grade and enjoys reading fantasy stories,
                  making his own music and participating in Mock Trial Club. 
                </p>
                <Link to="../faraz-tamboli" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="alumni__bio box-shadow hover imagezoom">
              <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              <div className="p-1.5">
                <span>2017 Finalist</span>
                <h3>Katie Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics.
                </p>
                <Link to="../katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="alumni__bio box-shadow hover imagezoom">
              <Image filename="Nicholas-LaJoie-Alumni-Card.jpg" />
              <div className="p-1.5">
                <span>2015 Finalist</span>
                <h3>Nicholas LaJoie</h3>
                <p>
                  Snigtha discovered Ferro-Sponge, a novel way to remove
                  microplastics and oil from contaminated water.
                </p>
                <Link to="../nicholas-lajoie" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default MehaaAmirthalingam;
